@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';

@mixin set-box-shadow($color) {
  box-shadow: 0 0 0 3px rgba($color, 0.5);
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid;
  border-color: $medium-gray;
  transition: border-color ease 0.2s;
  width: 100%;
  outline: none;
  padding-left: 20px;
  border-radius: 20px;
  height: 40px;
  appearance: none;
  color: $black;
  font-family: Metropolis;
  font-weight: map-get($text-weights, book);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 25px;
  background-image: url('~/public/assets/icons/angle-down.svg');
  background-position: calc(100% - 10px);
  background-repeat: no-repeat;

  &:focus {
    background-image: url('~/public/assets/icons/angle-up.svg');
    border-color: $navy-light;
    @include set-box-shadow($navy-lighter);
  }

  &:disabled {
    background-color: $lightest-gray;
    border: 1px solid $light-gray;
    color: $text-gray;

    &::placeholder {
      color: $text-gray;
    }
  }
}
