@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

:root {
  --header-height: 60px;
  --content-container-gap: 30px;
}

@include tablet-large {
  :root {
    --content-container-gap: 50px;
  }
}

@include desktop {
  :root {
    --header-height: 80px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Metropolis, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $lightest-gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--content-container-gap);
  padding: var(--content-container-gap) 0;
}
